<template>
    <div class="table">
        <div class="container">
            <div class="handle-box">
                <el-input v-model="searchValus" placeholder="手机号" class="handle-input mr10" @keyup.enter.native="search"
                    clearable></el-input>
                <el-select v-model="stationId" placeholder="选择驿站" class="handle-input mr10" clearable
                    v-if="stationList.length > 1">
                    <el-option v-for="item in stationList" :key="item.id" :label="item.stationName"
                        :value="item.id"></el-option>
                </el-select>
                <el-date-picker align="center" v-model="searchDate" type="date" class="handle-input mr10"
                    value-format="yyyy-MM-dd" placeholder="开锁日期">
                </el-date-picker>
                <el-time-picker align="center" v-model="searchTime" :picker-options="{
                    selectableRange: '00:00:00 - 23:59:59',
                    format: 'HH:mm'
                }" format="HH:mm" value-format="HH:mm" class="handle-input mr10" placeholder="开锁时间">
                </el-time-picker>
            </div>
            <div class="handle-box">
                <el-button type="primary" icon="search" @click="search">查询</el-button>
            </div>
            <el-table class="z_default_table" :empty-text="emptyText" :row-style="rowStyle" v-loading="tableLoading"
                element-loading-text="拼命加载中" element-loading-background="rgba(0, 0, 0, 0.5)" :data="tableData" border
                style="width: 100%" ref="multipleTable" use-virtual         height="600"
                @selection-change="handleSelectionChange">
                <el-table-column type="selection" align="center" width="40"></el-table-column>
                <el-table-column align="center" prop="phone" label="手机号码"></el-table-column>
                <el-table-column align="center" prop="stationId" label="所属驿站">
                    <template slot-scope="scope">
                        <div>{{ scope.row.stationName }}({{ scope.row.stationId }})</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="unlockDate" label="开锁时间"
                    :formatter="createTimeFormatter"></el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="pageIndex" :page-sizes="pageSizes" :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="tableTotal"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import global from "@/common/global";
import { decodeUserInfo, requestParams, currentDate, isEmpty, isNotEmpty } from "@/common/tools";
import upload from "@/components/subgroup/upload";
import bus from "@/common/bus";
export default {
    components: {
        upload
    },
    data() {
        return {
            companyJobList: [],
            perfectType: "",
            userInfo: decodeUserInfo(sessionStorage.getItem("userInfo")),
            pageSize: global.pageSize,
            pageSizes: global.pageSizes,
            pageIndex: 1,
            searchDate: null,
            searchTime: null,
            beforeRow: null,
            tableData: [], //表格数据
            tableTotal: 0,
            searchValus: "",
            checkForm: {},
            multipleSelection: [], //选中的行
            checkVisible: false,
            labelPosition: "right",
            indexArr: [],
            tableLoading: false,
            deliverForm: {},
            deliverVisible: false,
            delVisible: false,
            addVisible: false,
            editVisible: false,
            form: {
            },
            expressUserId: "",
            drawerLog: false,
            stationList: [],
            stationId: '',
            userExportVisible: false,
            emptyText: "",
            exportStationId: ""
        };
    },
    watch: {
        '$store.state.hasMenuList'(){
            this.getData();
        }
    },
    created() {
        this.getStationList();
        if (isNotEmpty(this.$store.state.hasMenuList)) this.getData();
    },
    updated() {

    },
    computed: {
        // handleStationList() {
        //     let stationList = this.stationList;
        //     let stationIds = this.$store.state.stationIds;
        //     return stationList.filter(item => stationIds.indexOf(item.id) != -1);
        // }
    },
    methods: {
        startExport() {
            this.userExportVisible = true;
        },
        rowStyle({ row, rowIndex }) {
            for (var i in this.indexArr) {
                if (rowIndex == this.indexArr[i].index) {
                    return "background:" + global.listColor + ";";
                }
            }
        },
        // 分页导航
        handleCurrentChange(val) {
            this.pageIndex = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.getData();
        },
        getStationList() {
            var data = {
                pageSize: 100000,
                pageIndex: this.pageIndex
            };
            this.$axios
                .post("/oa/station/list", this.$qs.stringify(data))
                .then(res => {
                    console.log(res);
                    this.stationList = res.data.data.rows;
                })
        },
        getData() {
            this.tableLoading = true;

            let searchDate = this.searchDate;
            let searchTime = this.searchTime;

            if (isEmpty(searchDate) && isNotEmpty(searchTime)) {
                this.tableLoading = false;
                this.$message.warning("请选择开锁日期");
                return;
            }

            var data = requestParams({
                pageSize: this.pageSize,
                pageIndex: this.pageIndex,
                searchValus: this.searchValus,
                stationId: this.stationId,
                searchDate,
                searchTime,
            }, false);

            this.emptyText = " ";
            this.$axios
                .post("/oa/unlockDoorLog/list", this.$qs.stringify(data))
                .then(({ data }) => {
                    console.log(data);
                    this.tableLoading = false;
                    if (data.code == 0) {
                        this.tableData = data.data.rows;
                        this.tableTotal = Number(data.data.total);
                        this.emptyText = "暂无数据";
                    } else {
                        this.$message.warning(data.message);
                    }
                })
                .catch(error => {
                    this.emptyText = "暂无数据";
                    this.tableLoading = false;
                });
        },
        search() {
            this.pageIndex = 1;
            //   alert(this.select_cate);
            this.getData();
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
            this.indexArr = [];
            if (this.multipleSelection.length >= 1) {
                for (var i in this.tableData) {
                    for (var j in this.multipleSelection) {
                        if (this.tableData[i].userId == this.multipleSelection[j].userId) {
                            this.indexArr[j] = {
                                index: i,
                                id: this.multipleSelection[j].userId
                            };
                        }
                    }
                }
            }
        },
        createTimeFormatter(row, column, cellValue, index) {
            return currentDate({
                timestamp: cellValue
            });
        }
    }
};
</script>

<style scoped>
.table {
    height: 100%;
}

.table,
.container {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
}

.table .el-table {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
}

.handle-box {
    margin-bottom: 20px;
}

.mr10 {
    margin-right: 10px;
}

.handle-select {
    width: 120px;
}

.search_select {
    width: 160px !important;
}

.handle-input {
    width: 160px;
    display: inline-block;
}

.del-dialog-cnt {
    font-size: 16px;
    text-align: center;
}

.select_name {
    display: inline-block;
    font-size: 14px;
}

.select_container {
    display: inline-block;
}

.select_container .el-date-editor.el-input {
    width: 140px;
}

.brandMs p {
    text-align: left;
}

.edit_dev>>>.el-transfer-panel {
    width: 350px;
}
</style>